import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Link,
  Route,
  Routes
} from "react-router-dom";
import { fetch } from 'whatwg-fetch'
import { isEmpty } from 'lodash'
import { Main } from './Main'
import { Settings } from './Settings'

const apiBase = '' //'http://localhost:3001'

class CWView extends React.Component<any, any>{
  private textArea

  constructor(props) {
    super(props)
    this.state = {
      numbers: undefined,
      value: undefined,
      errors: undefined,
      numbersFiles: undefined,
      support: undefined,
      loaded: false
    }
    this.textArea = React.createRef()
  }

  componentDidMount(): void {
    this.pollLoaded()
  }

  pollLoaded = () => {
    console.log("Polling loaded...")
    fetch(`${apiBase}/api/loaded`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json"
      }
    }).then(response => {
      const isLoaded = response
      if (isLoaded) {
        this.getSupport()
      } else {
        setTimeout(() => this.pollLoaded(), 2000)
      }
    }).catch(ex => {
      console.log("Error from pollLoaded", ex)
    })
  }

  getSupport = () => {
    fetch(`${apiBase}/api/support`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json"
      }
    }).then(response => {
      return response.json()
    }).then(json => {
     console.log("getSupport, response", json)
      this.setState({ support: json, loaded: true })
    }).catch(ex => {
      console.log("Error from getSupport", ex)
    })
  }

  getNumbersList = () => {
    fetch(`${apiBase}/api/getNumberFiles`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json"
      }
    }).then(response => {
      console.log("Response", response)
      if (response.ok) {
        console.log("Response OK")
        return response.json()
      } else {
        console.log("Reponse Error")
        throw Error
      }
    }).then(json => {
      this.setState({ numbersFiles: json })
    }).catch(ex => {
      console.log("Error from searchNumbers", ex)
    })
  }

  onChange = (event) => {
    this.setState({ value: event.target.value })
  }

  clearInput = () => {
    this.textArea.current.value = ""
    this.setState({ value: "" })
  }

  render() {
    const { numbers, errors, numbersFiles, support, loaded } = this.state

    if (!loaded) {
      return <h1>Loading...</h1>
    }

    return (
      <BrowserRouter>
        <div id="wrapper" className="container">
          <nav className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" role="navigation">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
              <img src="/static/assets/logo.png" className="img-fluid" alt="Coldwell Banker Logo" />
            </a>
            <hr className="sidebar-divider my-0" />
            <Link to="/">
              Search
            </Link>
            <Link to="/settings">
              Settings
            </Link>
          </nav>
          <div id="content-wrapper">
            <div id="content">
              <div className="container-fluid">
                <Routes>
                  <Route path="/" element={<Main support={support} />} />
                  <Route path="/settings" element={<Settings />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<CWView />, document.getElementById('root'))
