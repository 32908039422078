import * as React from 'react'
import { Search } from './Search'

export function Main(props) {

  const { support } = props
  let SupportComponent = null

  if (support && support.states) {
    SupportComponent = (
      <>
        <h2>Supports</h2>
        <span>States: {support.states.join(', ')}</span>
        <span>AreaCodes: {support.areaCodes.join(', ')}</span>
      </>
    )
  }

  return (
    <>
      <div className="d-sm-flex flex-column mb-4 mt-5">
        <h1 className="h3 mb-0 text-gray-800">Do Not Call List</h1>
        {SupportComponent}
      </div>
      <Search />
    </>
  )
}