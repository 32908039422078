import * as React from 'react'
import { isEmpty, set } from 'lodash'

const { useState, useEffect } = React


const apiBase = '' //'http://localhost:3001'

export function Search(props) {

  const [searchData, setSearchData] = useState("")
  const [searchResults, setSearchResults] = useState({ numbers: undefined, errors: undefined })

  const {
    numbers,
    errors
  } = searchResults

  const doSearch = () => {

    if (!isEmpty(searchData)) {

      const sending = JSON.stringify({ "data": searchData })
      console.log("Sending", sending)
      fetch(`${apiBase}/api/searchNumbers`, {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json"
        },
        "body": sending
      }).then(response => {
        console.log("Response", response)
        if (response.ok) {
          console.log("Response OK")
          return response.json()
        } else {
          console.log("Reponse Error")
          throw Error
        }
      }).then(json => {
        console.log("Json", json)
        setSearchResults(json)
        // this.setState({
        //   numbers: json['numbers'],
        //   errors: json['errors']
        // })
      }).catch(ex => {
        console.log("Error from searchNumbers", ex)
      })
    } else {
      console.log("nothing to search value:", searchData)
    }
  }

  return (
    <div>
      <h2>Search</h2>
      <p>
        Enter the phone numbers you want to search for.
      </p>
      <ol>
        <li>Numbers must be 10 digits.</li>
        <li>Each number must be separated by a comma or on new line.</li>
        {/* <li>The search will include area codes: 217, 219, 224, 260, 262, 269, 309, 317, 331, 414, 574, 608, 630, 708, 715, 765, 773, 773, 815, 847, 872, and 920.</li> */}
      </ol>
      <div className="form-group">
        <textarea
          id="search-numbers"
          className="form-control"
          onChange={(e) => setSearchData(e.target.value)}
          value={searchData}
        />
      </div>
      <button className="btn btn-primary mr-2" onClick={() => doSearch()}>
        Search
      </button>
      <button className="btn btn-secondary" onClick={() => setSearchData("")}>
        Clear
      </button>
      <div className="row">
        <div className="col">
          {!isEmpty(numbers) &&
            <div className="card shadow mb-4 mt-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                Results
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Can I call?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      numbers.map(number => {
                        const doNotCall = number.donotcall
                        return (
                          <tr className={doNotCall ? 'table-danger' : 'table-success'}>
                            <td>{number.value}</td>
                            <td className="text-center"><i className={'fa ' + (doNotCall ? 'fa-times-circle' : 'fa-check-circle')}></i></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
          {!isEmpty(errors) &&
            <div className="card shadow mb-4 mt-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                Errors
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <tbody>
                    {
                      errors.map(error => {
                        return (
                          <tr className={'table-danger'}>
                            <td>{error}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

